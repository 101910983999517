import Applications from "./Applications";
import { useState, useEffect } from "react";
import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import { Card, Row, Col, Button } from "react-bootstrap";
import ApplicationHeader from "./Applications/ApplicationHeader";
import getIsPayedUser from "@serie3/bewerbungshelferleinapi/Modules/User/Domain/getIsPayedUser";
import StripePricingTable from "../../Shared/Payment/StripePricingTable";
import loadPaymentData from "@serie3/common/Modules/Payment/Domain/loadPaymentData";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import PaymentDataForm from "@serie3/common/Modules/Payment/Forms/PaymentDataForm";
import PaymentForm from "../Settings/PaymentForm";

const ApplicationWrapper = () => {
  const [isPayedUser, setIsPayedUser] = useState(false);
  const editPaymentDataForm = PaymentDataForm.createPaymentDataForm();

  const { t } = useTranslation();

  useEffect(() => {
    getIsPayedUser(setIsPayedUser);
  }, []);

  return isPayedUser ? (
    <Applications />
  ) : (
    <ContentWrapper>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <h5>This is a payed feature</h5>
                  <p>
                    However: If you contact and follow me on
                    <a href="https://www.linkedin.com/in/felixdziekan/">
                      LinkedIn
                    </a>
                    or on{" "}
                    <a href="https://www.facebook.com/felix.dziekan">
                      Facebook
                    </a>
                    I'll give you full access for one year for free.
                  </p>
                  <iframe
                    src="https://www.youtube-nocookie.com/embed/Vp5i9M8T7DQ?si=m4u0J9sA8cD1EFzf&vq=hd1080"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StripePricingTable
                    priceTableId={"prctbl_1PijqTEr0zAuiASD8BR7rLoz"}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  );
};

export default ApplicationWrapper;
