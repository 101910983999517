import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ProfilePicture from "./Subcomponents/ProfilePicture";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";
import listHandler from "@serie3/common/Domain/listHandler";
import { Dropdown } from "react-bootstrap";
import downloadHandler from "@serie3/common/Shared/downloadHandler";
import { useState, useEffect } from "react";
import PDFModal from "../../Shared/Modals/PDFModal";
import { useTranslation } from "react-i18next";
import LoadingModal from "../../Shared/Modals/LoadingModal";
import PayModal from "../../Shared/Modals/PayModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Profile = ({
  index,
  profile,
  profiles = null,
  setProfiles = null,
  setProfileTitle = null,
  setPreviewId = null,
  setShowPreview = null,
  composeMode = false,
  setStep2 = null,
  applicationId = null,
  overwriteTitle = null,
  UseButton = null,
}) => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [fit, setFit] = useState(70);
  const [fitColor, setFitColor] = useState("#9fcc2e");
  const [profileId, setProfileId] = useState(null);

  const downloadUrl = ProfileConfig.endpoints.download + profileId;
  const { t } = useTranslation();

  console.log(profile);

  const deleteData = async (index, profile) => {
    listHandler.deleteFromList(
      index,
      crudApi.del(ProfileConfig.endpoints.delete, profileId),
      setProfiles,
      profiles
    );
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Freelance
    </Tooltip>
  );

  const cloneProfile = async (profile) => {
    setShowLoading(true);
    const response = await crudApi.justGet(
      ProfileConfig.endpoints.clone + profileId,
      setShowPayModal
    );
    setShowLoading(false);
    listHandler.newToList(response, profiles, setProfiles);
  };

  const downloadName = (
    profile.vorname +
    "_" +
    profile.name +
    "_" +
    profile.profiletitle
  ).replace(/ /g, "_");

  useEffect(() => {
    setProfileId(profile.id);
    const calculateFit = async () => {
      let fitUrl =
        ProfileConfig.endpoints.calculateFit + profile.id + "/" + applicationId;
      let fit = await crudApi.justGet(fitUrl);

      let fitColor =
        fit.data > 30 ? (fit.data > 50 ? "#9fcc2e" : "#fa9f1b") : "#e45240";
      //  setFitColor(fitColor);
      //   setFit(fit.data);
    };

    applicationId && calculateFit();
  }, [profile]);

  return (
    <>
      <PDFModal showSpinner={showSpinner} setShowSpinner={setShowSpinner} />
      <LoadingModal showSpinner={showLoading} setShowSpinner={setShowLoading} />
      <PayModal show={showPayModal} setShow={setShowPayModal} />

      <Col xs={12} sm={12} md={4}>
        <div className="card mb-3">
          <div className="card-body">
            <div className="d-flex justify-content-end gap-1">
              {profile.freelance === true ? (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-icon btn-hover btn-white shadow-none text-green add-tooltip"
                    data-bs-original-title="Freelance"
                  >
                    <i className="psi-star fs-5"></i>
                  </button>
                </OverlayTrigger>
              ) : null}
              <div className="dropdown">
                <Dropdown>
                  <Dropdown.Toggle
                    id="profile-id"
                    className="btn btn-icon btn-sm btn-hover btn-light show horizontalDropdownDots"
                  >
                    <i className="pli-dot-horizontal fs-4"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {!composeMode && (
                      <Dropdown.Item
                        onClick={() => {
                          setShowSpinner(true);
                          setProfileTitle(downloadName);
                          downloadHandler(
                            downloadUrl,
                            downloadName + ".pdf",
                            null,
                            setShowSpinner
                          );
                        }}
                      >
                        <i className="pli-file-download fs-5 me-2"></i>
                        {t("Backend.Dropdown.Download")}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={() => {
                        setProfileTitle(downloadName);
                        setPreviewId(profileId);
                        setShowPreview(true);
                      }}
                    >
                      <i className="pli-eye-visible fs-5 me-2" />
                      {t("Backend.Dropdown.Preview")}
                    </Dropdown.Item>
                    {!composeMode && (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            navigate("/profile/edit/" + profileId);
                          }}
                        >
                          <i className="pli-pen-5 fs-5 me-2" />
                          {t("Backend.Dropdown.Edit")}
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() => {
                            cloneProfile(profile);
                          }}
                        >
                          <i className="pli-file-copy-2 fs-5 me-2" />
                          {t("Backend.Dropdown.Clone")}
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <hr className="dropdowndivider" />
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-danger"
                          onClick={() => {
                            deleteData(index, profile);
                          }}
                        >
                          <i className="pli-trash fs-5 me-2" />
                          {t("Backend.Dropdown.Delete")}
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div
              className="text-center position-relative"
              onClick={() => {
                composeMode &&
                  typeof setStep2 === "function" &&
                  setStep2(
                    profileId,
                    fit,
                    fitColor,
                    profile.vorname + " " + profile.name
                  );
              }}
            >
              <div className="pt-2 pb-3">
                {profileId && <ProfilePicture profileId={profileId} />}
              </div>
              <span className="h4 stretched-link btn-link">
                {overwriteTitle ? overwriteTitle : profile.profiletitle}
              </span>
              <p className="text-muted">
                {t("Backend.Profiles.Index.Template")}: {profile.cv_template}
              </p>

              {UseButton && <UseButton />}
              {/*fit && (
                <Col
                  className="d-flex justify-content-center align-items-center"
                  style={{ color: fitColor }}
                >
                  <div
                    className="doughnut-container me-2"
                    style={{
                      width: "40px",
                      height: "40px",
                      fontWeight: "bolder",
                      background: `conic-gradient(${fitColor} 0% ${fit}%, gray ${fit}% 100%)`,
                    }}
                  >
                    <div
                      className="doughnut-inner"
                      style={{ width: "20px", height: "20px" }}
                    ></div>
                  </div>
                  <div>
                    <b>Fit</b>
                  </div>
                </Col>
              )*/}
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Profile;
