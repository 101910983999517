import NewModal from "../../../Shared/Modals/NewModal";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import LoadApplications from "@serie3/bewerbungshelferleinapi/Modules/Applications/Domain/LoadApplications";
import { useState, useEffect } from "react";
import { ApplicationConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import ApplicationItem from "../../user/Applications/ApplicationItem";
import Carousel from "react-bootstrap/Carousel";
import Profile from "../../Profiles/Profile";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";
import {
  Row,
  Form,
  Button,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
  Modal
} from "react-bootstrap";
import ApplicationComposeForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/ApplicationComposeForm";
import ApplicationCompose from "./ApplicationCompose";
import removeGenderTags from "@serie3/bewerbungshelferleinapi/Domain/removeGenderTags";
import TextArea from "../../../Shared/Form/TextArea";
import TextField from "../../../Shared/Form/TextField";

const ComposeApplicationModal = ({
  show,
  setShow,
  applications,
  setApplications,
  setShowPreview,
  setPreviewId,
  setPreviewProfileTitle,
}) => {
  const { t } = useTranslation();
  const [todoApplications, setTodoApplications] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [choosenProfile, setChoosenProfile] = useState(null);
  const [application, setApplication] = useState(null);
  const [stepTitle, setStepTitle] = useState("Compose your application");
  const [profileTitle, setProfileTitle] = useState("");
  const [applicationFit, setApplicationFit] = useState(null);
  const [fitColor, setFitColor] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [applicantFullName, setApplicantFullName] = useState ('');

  const loadTodoApplications = async () => {
    await LoadApplications(
      setTodoApplications,
      ApplicationConfig.endpoints.getTodo
    );
  };

  const loadProfiles = async () => {
    const endpoint = ProfileConfig.endpoints.getAll;
    await crudApi.getAll(endpoint, setProfiles, "profile");
  };

  const composeApplicationForm = ApplicationComposeForm.createApplicationForm(
    applications,
    setApplications,
    setShow,
    setShowSpinner
  );
  const composeFormik = useFormik(composeApplicationForm);

  const setStep1 = (application) => {
    setSlideIndex(1);
    setApplication(application);
  };

  const setStep2 = (profileId, applicationFit, applicationFitColor, fullName) => {
    setSlideIndex(2);
    setApplicationFit(applicationFit);
    setFitColor(applicationFitColor);
    composeFormik.setFieldValue("profile.id", profileId);
    setApplicantFullName(fullName);
    let mailAddress = "";
    const mailSubject = t('Backend.Applications.Modal.MailSubject', { jobTitle: application.job_title });
    let mailText = t('Backend.Applications.Modal.MailText', { jobTitle: application.job_title });
    mailText += fullName;
    composeFormik.setFieldValue("application.id", application.id);
    composeFormik.setFieldValue("mail_subject", mailSubject);
    composeFormik.setFieldValue("mail_text", mailText);
    composeFormik.setFieldValue("mail_address", mailAddress);
  };

  const setStep3 = () => {
    if(composeFormik.values.mail_address && composeFormik.values.mail_address.trim() !== '') {
      setSlideIndex(3);
    }else{
      composeFormik.setFieldError('mail_address', 'Email is required');
    }

  };

  const UseButton = () => {
    return (
      <Col
        className="d-flex justify-content-center align-items-center"
        style={{ color: fitColor }}
      >
        <Button className="btn btn-success">Use</Button>
      </Col>
    );
  };

  const UpdateProfileTitle = () => {
    return (
      <>
        {profileTitle !== (choosenProfile?.profiletitle || "") ? (
          <Button
            className="btn btn-primary btn-success"
            onClick={() => {
              if (choosenProfile) {
                setProfileTitle(choosenProfile.profiletitle);
                composeFormik.setFieldValue(
                  "updated_title",
                  choosenProfile.profiletitle
                );
              }
            }}
          >
            Reset profile title
          </Button>
        ) : (
          <Button
            className="btn btn-primary btn-success"
            onClick={() => {
              if (application) {
                let modifiedString = removeGenderTags(application.job_title);
                setProfileTitle(modifiedString);
                composeFormik.setFieldValue("updated_title", modifiedString);
              }
            }}
          >
            Update profile title
          </Button>
        )}
      </>
    );
  };

  useEffect(() => {
    const getProfile = async () => {
      let response = await crudApi.justGet(
        ProfileConfig.endpoints.show + composeFormik.values.profile.id
      );
      if (response.ok) {
        console.log(response.data);
        setChoosenProfile(response.data);
        setProfileTitle(response.data.profiletitle);
      }
    };

    switch (slideIndex) {
      case 0:
        setStepTitle("Compose your application");
        break;
      case 1:
        setStepTitle("Choose your profile");
        break;
      case 2:
        setStepTitle("Create your mail");
        getProfile();
        break;
      case 3:
        setStepTitle("Check and Send!");
        break;
    }
  }, [slideIndex]);

  const Save = () => {
    return (
      <>
        {slideIndex === 3 && (
          <Button
            onClick={() => composeFormik.handleSubmit()}
            className="btn btn-success btn btn-primary"
          >
            <i className="psi-mail-send fs-3 me-2"></i>
            <b>Send it!</b>
          </Button>
        )}

        {slideIndex === 2 && (
          <Button onClick={() => setStep3()} className="btn btn-success">
            Check and send
          </Button>
        )}
        {slideIndex > 0 && (
          <Button onClick={() => setSlideIndex(slideIndex - 1)}> Back </Button>
        )}
      </>
    );
  };

  const handleSlideLoad = () => {
    if (slideIndex === 1) {
      loadProfiles();
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      AI for calculating your project fit comming soon!
    </Tooltip>
  );

  const SpinnerModal = () => {
    return (
      <Modal show={showSpinner} setShow={setShowSpinner}>
      <Modal.Body
        style={{ textAlign: "center", backgroundColor: "transparent" }}
      >
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div> {t("Backend.Applications.Modal.Send")}</div>
      </Modal.Body>
    </Modal>
    );
  };

  return (
    <>
      <SpinnerModal />
      <NewModal
        show={show}
        setShow={setShow}
        ExtraButton={Save}
        title={stepTitle}
        onExit={() => {
          setSlideIndex(0);
          composeFormik.resetForm();
          setProfiles([]);
          setChoosenProfile(null);
        }}
        onShow={() => {
          loadTodoApplications();
        }}
      >
        <Carousel
          activeIndex={slideIndex}
          interval={null}
          controls={false}
          onSlid={handleSlideLoad}
          indicators={false}
        >
          <Carousel.Item>
            {Array.isArray(todoApplications) &&
              todoApplications.length > 0 &&
              todoApplications.map((application, index) => {
                return (
                  <ApplicationItem
                    application={application}
                    index={index}
                    key={application.id}
                    composeMode={true}
                    setStep1={setStep1}
                  />
                );
              })}
          </Carousel.Item>
          <Carousel.Item>
            <Row className="d-flex align-items-stretch">
              {Array.isArray(profiles) &&
                profiles.length > 0 &&
                profiles.map((profile, index) => {
                  return (
                    <Profile
                      profile={profile}
                      index={index}
                      composeMode={true}
                      setStep2={setStep2}
                      applicationId={application.id}
                      setPreviewId={setPreviewId}
                      setShowPreview={setShowPreview}
                      setProfileTitle={setPreviewProfileTitle}
                      UseButton={UseButton}
                    />
                  );
                })}
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Form.Group className={"form-inline"}>
              <Row className="mb-3">
                <Col className="col-2">To:</Col>
                <Col>
                  <TextField
                    formik={composeFormik}
                    valueName={"mail_address"}
                    placeholder={"Address"}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="col-2">Subject:</Col>
                <Col>
                  <TextField
                    formik={composeFormik}
                    valueName={"mail_subject"}
                    placeholder={"Subject"}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="col-2">Text:</Col>
                <Col>
                  <TextArea
                    formik={composeFormik}
                    valueName={"mail_text"}
                    rows={15}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Carousel.Item>
          <Carousel.Item>
            <Card>
              <Card.Body>
                <Row className="mb-3">
                  <Col className="col-2" style={{ fontWeight: "bolder" }}>
                    To:
                  </Col>
                  <Col>{composeFormik.values["mail_address"]}</Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-2" style={{ fontWeight: "bolder" }}>
                    Subject:
                  </Col>
                  <Col>{composeFormik.values["mail_subject"]}</Col>
                </Row>
                <hr />
                <Row className="mb-5">
                  <Col className="col-2" style={{ fontWeight: "bolder" }}>
                    Text:
                  </Col>
                  <Col
                    style={{
                      backgroundColor: "rgba(117,134,142,0.05)",
                      padding: "12px 15px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: composeFormik.values["mail_text"].replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                </Row>
                <h5 className="mt-3">Data:</h5>
                <hr />
                <Row>
                  {choosenProfile && (
                    <Profile
                      index={1}
                      profile={choosenProfile}
                      composeMode={true}
                      overwriteTitle={profileTitle}
                      setPreviewId={setPreviewId}
                      setShowPreview={setShowPreview}
                      setProfileTitle={setPreviewProfileTitle}
                    />
                  )}
                  <Col className="d-flex flex-column justify-content-center align-items-center">
                    <Row className="w-100">
                      <Col className="d-flex justify-content-center">
                        <div
                          className="doughnut-container"
                          style={{
                            background: `conic-gradient(${fitColor} 0% 0%, gray 100% 100%)`,
                          }}
                        >
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <div
                              className="doughnut-inner"
                              style={{ color: fitColor }}
                            >
                              <small>
                                AI
                                <br />
                                soon
                              </small>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </Col>
                    </Row>
                    <Row className="w-100 mt-3">
                      <Col className="d-flex justify-content-center">
                        <UpdateProfileTitle />
                      </Col>
                    </Row>
                  </Col>
                  {application && (
                    <ApplicationCompose application={application} />
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Carousel.Item>
        </Carousel>
      </NewModal>
    </>
  );
};

export default ComposeApplicationModal;
